"use client";

import { assertError } from "@holdenmatt/ts-utils";
import { ReactNode, useCallback } from "react";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";

import { cn, ErrorMessage } from "../lib";
import { Stack } from "./Stack";
import { Button } from "./ui/button";
import { Progress } from "./ui/progress";

export type DropzoneProps = Omit<DropzoneOptions, "multiple" | "onError"> & {
  /**
   * Optional children to put in the center of the Dropzone.
   *
   * If not provided, a default "Choose file" button will be shown.
   */
  children?: ReactNode;

  /**
   * Placeholder text shown by default (e.g. what file types are allowed).
   */
  placeholder?: string;

  /**
   * Placeholder to show if a drag operation will be accepted.
   */
  placeholderAccept?: string;

  /**
   * Placeholder to show if a drag will be rejected.
   */
  placeholderReject?: string;

  /**
   * Show an upload progress indicator instead of placeholder text.
   */
  progress?: number | undefined;

  /**
   * Toast description to show if a file type is invalid.
   */
  invalidFileMessage?: string;

  /**
   * Classes to add on the outer div container.
   */
  className?: string;

  /**
   * Classes to add on the centered inner area.
   */
  innerClassName?: string;

  onError?: (error: ErrorMessage) => void;
} & (
    | {
        multiple: true;
        onFile?: (files: File[]) => void;
      }
    | {
        multiple: false;
        onFile?: (file: File) => void;
      }
  );

/**
 * Render a file dropzone with a drop target and "Choose file" button.
 */
export const Dropzone = ({
  children,
  placeholder,
  placeholderAccept = "Drop here",
  placeholderReject = "Invalid file type",
  progress,
  invalidFileMessage,
  className,
  innerClassName,
  multiple,
  onFile,
  onError,
  ...options
}: DropzoneProps) => {
  const handleDropAccepted = useCallback(
    (files: File[]) => {
      if (multiple) {
        onFile?.(files);
      } else {
        if (files.length === 1) {
          onFile?.(files[0]);
        } else {
          assertError(`Unexpected file count: ${files.length}`);
        }
      }
    },
    [multiple, onFile]
  );

  const handleDropRejected = useCallback(
    (rejections: FileRejection[]) => {
      if (!multiple && rejections.length > 1) {
        onError?.({
          title: "Too many files",
          description: "Please import a single file at a time",
        });
      } else {
        // Are there any reasons for this besides invalid file types?
        onError?.({
          title: "Invalid file",
          description: invalidFileMessage,
        });
      }
    },
    [multiple, invalidFileMessage, onError]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    ...options,
    multiple,
    onDropRejected: handleDropRejected,
    onDropAccepted: handleDropAccepted,
  });

  return (
    <div
      className={cn(
        "h-full cursor-pointer select-none rounded-lg border-2 border-dashed bg-white transition-all",
        "hover:bg-gray-50",
        isDragActive && "border-indigo-500 bg-indigo-50 hover:bg-indigo-50",
        isDragReject && "border-destructive bg-red-50 hover:bg-red-50",
        className
      )}
      {...getRootProps()}
      tabIndex={-1}
    >
      <input {...getInputProps()} />
      <Stack
        className={cn(
          "text-muted-foreground h-full items-center justify-center gap-4 p-8",
          isDragActive && "text-indigo-500",
          isDragReject && "text-destructive",
          innerClassName
        )}
      >
        {children ? (
          children
        ) : (
          <Button variant={isDragReject ? "destructive" : "default"}>Choose file</Button>
        )}
        {progress !== undefined ? (
          <div className="flex h-4 items-center">
            <Progress value={progress} className="h-1 w-[180px]" />
          </div>
        ) : (
          <div className="text-xs">
            {isDragReject
              ? placeholderReject
              : isDragActive
              ? placeholderAccept
              : placeholder}
          </div>
        )}
      </Stack>
    </div>
  );
};
