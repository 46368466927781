"use client";

import { X } from "lucide-react";
import React, { useRef } from "react";

import { cn, mergeRefs } from "../../lib";

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  inputClassName?: string; // Added
  error?: boolean; // Added
  clearIcon?: boolean; // Added
  onValueChange?: (value: string) => void; // Added
}

export const InputStyles =
  "border-input bg-background ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-10 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50";

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      inputClassName,
      clearIcon = false,
      error = false,
      onValueChange,
      ...props
    },
    ref
  ) => {
    const localRef = useRef<HTMLInputElement>();
    return (
      <div className={cn("relative", className)}>
        <input
          className={cn(
            InputStyles,
            error && "text-destructive focus-visible:ring-destructive",
            inputClassName
          )}
          ref={mergeRefs([ref, localRef])}
          onChange={(e) => {
            const value = e.target.value;
            onValueChange?.(value);
          }}
          {...props}
        />
        {clearIcon && localRef.current?.value && (
          <div
            className="absolute inset-y-0 right-3 -mx-2 flex cursor-pointer items-center px-2"
            onClick={() => onValueChange?.("")}
          >
            <X className="h-4 w-4 opacity-50" />
          </div>
        )}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
