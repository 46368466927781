"use client";

import { Check, ChevronDown } from "lucide-react";
import React, { useMemo } from "react";

import { cn } from "../lib";
import { Button } from "./ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

// Limit items in the list for now, until we virtualize it.
const MAX_ITEMS = 100;

export type ComboboxItem = {
  /**
   * Case-sensitive value represented by the item.
   */
  value: string;

  /**
   * Optional label displayed to users (defaults to value).
   */
  label?: string;
};

type InternalComboboxItem = ComboboxItem & {
  /**
   * Internal key used by cmdk (must be lowercase).
   */
  key?: string;
};

export type ComboboxProps = {
  items?: readonly ComboboxItem[];
  placeholder?: string;
  searchPlaceholder?: string;
  emptyPlaceholder?: string;
  value?: string;
  className?: string;
  contentClassName?: string;

  /**
   * Optional controlled state for the value of the search input.
   */
  search?: string;

  /**
   * Event handler called when the search value changes.
   */
  onSearchChange?: (search: string) => void;

  /**
   * Event handler called when an item is selected.
   */
  onValueChange?: (value: string) => void;
};

export const Combobox = ({
  items,
  placeholder = "Choose...",
  searchPlaceholder = "Search...",
  emptyPlaceholder = "Not found",
  value = "",
  className,
  contentClassName,
  search,
  onSearchChange,
  onValueChange,
}: ComboboxProps) => {
  const [open, setOpen] = React.useState(false);

  const internalItems: InternalComboboxItem[] | undefined = useMemo(() => {
    if (items === undefined) {
      return undefined;
    }

    const internal = addKeys(items).map(({ key, value, label }) => ({
      key,
      value,
      label: label ? label : value,
    }));

    return internal;
  }, [items]);

  const currentItem = value
    ? internalItems?.find((item) => item.value === value)
    : undefined;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            "w-full justify-between px-3 font-normal",
            !currentItem && "text-muted-foreground",
            className
          )}
        >
          <span className="truncate">
            {currentItem ? currentItem.label : placeholder}
          </span>
          <ChevronDown className="ml-2 h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn("w-[200px] p-0", contentClassName)}>
        <Command>
          <CommandInput
            placeholder={searchPlaceholder}
            value={search}
            onValueChange={onSearchChange}
          />
          <CommandList>
            {internalItems && internalItems.length === 0 && (
              <CommandEmpty>{emptyPlaceholder}</CommandEmpty>
            )}
            <CommandGroup>
              {internalItems?.slice(0, MAX_ITEMS).map((item) => (
                <CommandItem
                  key={item.key}
                  value={item.key}
                  onSelect={(newKey) => {
                    if (newKey !== currentItem?.key) {
                      onValueChange?.(item.value);
                    }
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === item.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  <span className="line-clamp-2">{item.label}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

/**
 * Add unique lowercase keys to items for cmdk to use.
 */
const addKeys = (items: readonly ComboboxItem[]): InternalComboboxItem[] => {
  const keys = new Set();
  return items.map((item) => {
    let key = item.value.toLowerCase();
    while (keys.has(key)) {
      key = key + "_";
    }
    return {
      ...item,
      key,
    };
  });
};
