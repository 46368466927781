"use client";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";

import { cn } from "../../lib";

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipRoot = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(
  (
    { className, side = "bottom", sideOffset = 10, collisionPadding = 10, ...props },
    ref
  ) => (
    <TooltipPrimitive.Content
      ref={ref}
      side={side}
      sideOffset={sideOffset}
      collisionPadding={collisionPadding}
      className={cn(
        "bg-popover text-popover-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-[1000] overflow-hidden rounded-md border px-3 py-1.5 text-sm shadow-md",
        className
      )}
      {...props}
    />
  )
);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

type TooltipProps = React.ComponentPropsWithoutRef<typeof TooltipContent> &
  Pick<
    TooltipPrimitive.TooltipProps,
    "open" | "defaultOpen" | "onOpenChange" | "delayDuration"
  > & {
    children: React.ReactNode;
    content: React.ReactNode;

    /* Optional keyboard shortcut string */
    shortcut?: string;
  };

const Tooltip = ({
  children,
  content,
  shortcut,
  open,
  defaultOpen,
  onOpenChange,
  delayDuration,
  ...props
}: TooltipProps) => {
  const hasContent = content !== undefined && content !== "";
  return (
    <TooltipRoot
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      delayDuration={delayDuration}
    >
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      {hasContent && (
        <TooltipPrimitive.Portal>
          <TooltipContent {...props}>
            {content}
            {shortcut && (
              <div className="ml-3 inline-flex gap-1 text-gray-700">
                {shortcut.split(" ").map((value) => (
                  <span key={value} className="rounded bg-gray-200 px-1">
                    {value}
                  </span>
                ))}
              </div>
            )}
          </TooltipContent>
        </TooltipPrimitive.Portal>
      )}
    </TooltipRoot>
  );
};

export { Tooltip, TooltipContent, TooltipProvider, TooltipRoot, TooltipTrigger };
