import { cn } from "../lib";

export type GroupProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  className?: string;
};

/**
 * Group children horizontally.
 */
export const Group = ({ children, className, ...rest }: GroupProps) => (
  <div className={cn("flex gap-4", className)} {...rest}>
    {children}
  </div>
);
