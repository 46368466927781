"use client";

import { Minus, Plus } from "lucide-react";
import React, { ReactNode, useCallback, useRef, useState } from "react";
import { useScroll } from "react-use";

import { cn } from "../lib";
import { Group } from "./Group";
import { Stack } from "./Stack";
import { Button } from "./ui/button";
import { ScrollArea } from "./ui/scroll-area";
import { Separator } from "./ui/separator";
import { Tooltip } from "./ui/tooltip";

type RowProps = {
  /**
   * Content on the right.
   */
  children: ReactNode;

  /**
   * Optional label on the left.
   */
  label?: string;

  /**
   * Show a tooltip on the left side.
   */
  tooltip?: string;

  /**
   * Add a flexbox to align children to the start or end of the row.
   */
  justify?: "start" | "end";

  /**
   * Columns (out of 4) to use for the label.
   */
  labelCols?: 1 | 2;

  className?: string;
};

/**
 * A single inspector row with a control and optional label.
 */
const Row = ({
  children,
  label,
  tooltip,
  justify,
  labelCols = 1,
  className,
}: RowProps) => (
  <Tooltip content={tooltip} side="left">
    <div
      className={cn("items-center py-1", label ? "grid grid-cols-4" : "flex", className)}
    >
      {label && (
        <label
          className={cn(
            "text-muted-foreground flex h-8 select-none items-center text-sm",
            labelCols === 2 ? "col-span-2" : "col-span-1"
          )}
        >
          {label}
        </label>
      )}
      <div
        className={cn(
          label ? (labelCols === 2 ? "col-span-2" : "col-span-3") : "flex w-full",
          justify === "start" && "flex justify-start",
          justify === "end" && "flex justify-end"
        )}
      >
        {children}
      </div>
    </div>
  </Tooltip>
);

type SectionProps = {
  /**
   * Contents of the panel.
   */
  children?: ReactNode;

  /**
   * Optional title for the section.
   */
  title?: ReactNode;

  /**
   * Small text description above the section content.
   */
  description?: ReactNode;

  /**
   * Optional icon in the section header.
   */
  icon?: ReactNode;

  /**
   * Show a tooltip for the section title row.
   */
  tooltip?: string;

  /**
   * Classes added to the section container.
   */
  className?: string;

  /**
   * Classes added to the title div.
   */
  titleClassName?: string;

  /**
   * The section header (including any icon) was clicked.
   */
  onHeaderClick?: () => void;

  /**
   * The icon was clicked.
   */
  onIconClick?: () => void;
};

/**
 * A section of the inspector panel.
 */
const Section = ({
  children,
  title,
  description,
  icon,
  tooltip,
  className,
  titleClassName,
  onHeaderClick,
  onIconClick,
}: SectionProps) => (
  <div className={cn("flex flex-col gap-2 px-4 py-3", className)}>
    {title && (
      <Tooltip content={tooltip} side="left">
        <div
          onClick={onHeaderClick}
          className={cn(
            "flex select-none items-center justify-between text-sm font-medium text-gray-900",
            titleClassName
          )}
        >
          <span className="w-full">{title}</span>
          {icon && (
            <Button
              variant="ghost"
              size="icon-sm"
              className="group"
              onClick={onIconClick}
            >
              {icon}
            </Button>
          )}
        </div>
      </Tooltip>
    )}
    {description && <div className="text-muted-foreground text-xs">{description}</div>}
    {children && <div className="flex flex-col gap-2">{children}</div>}
  </div>
);

/**
 * An inspector section with a +/- toggle to expand/collapse children.
 */
const ExpandableSection = ({
  children,
  initialOpen = false,
  onOpenChange,
  ...rest
}: SectionProps & {
  /**
   * Controls the initial open state of the section.
   */
  initialOpen?: boolean;

  /**
   * Callback invoked when the open state changes.
   */
  onOpenChange?: (open: boolean) => void;
}) => {
  const [open, setOpen] = useState(initialOpen);

  const handleToggle = useCallback(() => {
    setOpen(!open);
    if (onOpenChange) {
      onOpenChange(!open);
    }
  }, [open, onOpenChange]);

  return (
    <Section
      icon={open ? <Minus className="h-5 w-5" /> : <Plus className="h-5 w-5" />}
      titleClassName={cn(!open && "font-normal text-gray-500 hover:text-gray-900")}
      onHeaderClick={handleToggle}
      onIconClick={handleToggle}
      {...rest}
    >
      {open ? children : null}
    </Section>
  );
};

export type InspectorProps = {
  /**
   * Title of the inspector.
   */
  title?: React.ReactNode;

  /**
   * Inspector contents.
   */
  children: React.ReactNode;

  /**
   * Icon to render in the top/right (e.g. a menu icon).
   */
  icon?: React.ReactNode;

  className?: string;
};

export const Inspector = ({ title, children, icon, className }: InspectorProps) => {
  const scrollRef = useRef(null);
  const { y } = useScroll(scrollRef);
  return (
    <Stack className={cn("gap-0 rounded-lg border bg-white shadow-sm", className)}>
      {title ? (
        <Section>
          <Group>
            <div className="flex grow">
              <div className="flex h-8 w-0 min-w-full select-none items-center truncate text-base font-medium leading-7">
                {title}
              </div>
            </div>
            {icon}
          </Group>
        </Section>
      ) : null}
      {y > 0 && <Separator />}
      {/* Workaround for this issue: https://github.com/shadcn-ui/ui/issues/1421 */}
      <ScrollArea ref={scrollRef} className="[&>div>div]:!block">
        {children}
      </ScrollArea>
    </Stack>
  );
};

Inspector.Row = Row;
Inspector.Section = Section;
Inspector.ExpandableSection = ExpandableSection;
