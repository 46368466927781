import { cn } from "../lib";

export type StackProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  className?: string;
};

/**
 * Stack children vertically.
 */
export const Stack = ({ children, className, ...rest }: StackProps) => (
  <div className={cn("flex flex-col gap-4", className)} {...rest}>
    {children}
  </div>
);
