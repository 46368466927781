"use client";

import * as ToolbarPrimitive from "@radix-ui/react-toolbar";
import * as React from "react";

import { cn } from "../../lib";

const ToolbarSeparator = ToolbarPrimitive.Separator;
const ToolbarLink = ToolbarPrimitive.Link;
const ToolbarToggleGroup = ToolbarPrimitive.ToggleGroup;
const ToolbarToggleItem = ToolbarPrimitive.ToggleItem;

const Toolbar = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <ToolbarPrimitive.Root
      className={cn("flex w-min items-center rounded-md p-2 shadow-md", className)}
      ref={ref}
      {...props}
    ></ToolbarPrimitive.Root>
  );
});
Toolbar.displayName = "Toolbar";

const ToolbarButton = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Button>,
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Button>
>(({ className, ...props }, ref) => {
  return (
    <ToolbarPrimitive.Button
      className={cn(
        "hover:bg-accent hover:text-accent-foreground inline-flex rounded-sm p-3 text-gray-700",
        "disabled:text-gray-300", // Added
        className
      )}
      ref={ref}
      {...props}
    ></ToolbarPrimitive.Button>
  );
});
ToolbarButton.displayName = "ToolbarButton";

export {
  Toolbar,
  ToolbarButton,
  ToolbarLink,
  ToolbarSeparator,
  ToolbarToggleGroup,
  ToolbarToggleItem,
};
