"use client";

import { MouseEventHandler, ReactNode } from "react";

import { cn } from "../lib";
import { Button } from "./ui/button";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";

type SimpleDialogProps = {
  title: ReactNode;
  description: ReactNode;
  children?: ReactNode;
  className?: string;
};

/**
 * Render a simple dialog, with title, description, and children.
 */
export const SimpleDialogContent = ({
  title,
  description,
  children,
  className,
}: SimpleDialogProps) => (
  <DialogContent className={className}>
    <DialogHeader className="space-y-4">
      <DialogTitle>{title}</DialogTitle>
      <DialogDescription>{description}</DialogDescription>
    </DialogHeader>

    {children}
  </DialogContent>
);

type ConfirmDialogProps = SimpleDialogProps & {
  actionText?: string;
  actionVariant?: "default" | "destructive";
  actionClassName?: string;
  onActionClick?: MouseEventHandler<HTMLButtonElement>;

  cancelText?: string;
  cancelClassName?: string;
  onCancelClick?: MouseEventHandler<HTMLButtonElement>;

  onOpenChange?: (open: boolean) => void;
};

/**
 * Render a confirmation dialog, with action/cancel buttons.
 */
export const ConfirmDialogContent = ({
  children,
  actionText = "OK",
  actionVariant = "default",
  actionClassName,
  cancelText = "Cancel",
  cancelClassName,
  onActionClick,
  onCancelClick,
  onOpenChange,
  ...rest
}: ConfirmDialogProps) => (
  <SimpleDialogContent {...rest}>
    {children}

    <DialogFooter>
      {cancelText && (
        <Button
          variant="outline"
          className={cn("mt-2 min-w-[80px] sm:mt-0", cancelClassName)}
          onClick={(e) => {
            onOpenChange?.(false);
            onCancelClick?.(e);
          }}
        >
          {cancelText}
        </Button>
      )}
      {actionText && (
        <Button
          variant={actionVariant}
          className={cn("min-w-[80px]", actionClassName)}
          onClick={(e) => {
            onOpenChange?.(false);
            onActionClick?.(e);
          }}
        >
          {actionText}
        </Button>
      )}
    </DialogFooter>
  </SimpleDialogContent>
);
